import { PROCESSING_INTEGRATION, UPDATE_INTEGRATION } from '../actions/types';

export default function (state = [], action) {
  switch (action.type) {
    case PROCESSING_INTEGRATION: {
      const integrations = state.map((integration) => {
        if (integration.id === action.payload) {
          return { ...integration, status: 'processing' };
        }
        return integration;
      });
      return integrations;
    }
    case UPDATE_INTEGRATION: {
      const integrations = state.map((integration) => {
        if (integration.id === action.payload.id) {
          return { ...action.payload };
        }
        return integration;
      });
      return integrations;
    }
    default:
      return state;
  }
}
