import {
  ADD_PRESENCE_MEMBER,
  DELETE_PRESENCE_MEMBER,
  SET_PRESENCE_MEMBERS,
  SET_LOCK,
  LOCK_DENIED,
  LOCK_REQUESTED,
} from '../actions/types';

export default function (state = [], action) {
  switch (action.type) {
    case SET_PRESENCE_MEMBERS: {
      return action.payload;
    }
    case ADD_PRESENCE_MEMBER: {
      const members = state.slice();
      members.push(action.payload);
      return members;
    }
    case DELETE_PRESENCE_MEMBER: {
      return state.slice().filter((member) => member.id !== action.payload.id);
    }
    case SET_LOCK: {
      return state.slice().map((member) => ({ ...member, isRequestingLock: false }));
    }
    case LOCK_REQUESTED: {
      return state.slice().map((member) => {
        if (member.id === action.payload.id) {
          return { ...member, isRequestingLock: true };
        }
        return member;
      });
    }
    case LOCK_DENIED: {
      return state.slice().map((member) => {
        if (member.id === action.payload.id) {
          return { ...member, isRequestingLock: false };
        }
        return member;
      });
    }
    default:
      return state;
  }
}
