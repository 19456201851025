import { ADD_KEYWORD, DELETE_KEYWORD } from '../actions/types';

export default function (state = [], action) {
  switch (action.type) {
    case ADD_KEYWORD: {
      const keywords = state.slice();
      if (!keywords.find((kw) => kw.id === action.payload.id)) {
        keywords.push(action.payload);
      }
      return keywords;
    }
    case DELETE_KEYWORD: {
      return state.filter((keyword) => keyword.id !== action.payload.id);
    }
    default:
      return state;
  }
}
