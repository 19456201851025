import {
  UNPUBLISH_STORY,
  UPDATE_STORY_CONTENT,
  UPDATE_STORY_TITLE,
  UPDATE_STORY_SLUG,
  UPDATE_STORY_HAS_UNCONFIRMED_CHANGES,
  UPDATE_STORY_SEO_TITLE,
  UPDATE_STORY_SEO_DESCRIPTION,
  UPDATE_STORY_SEO_SLUG,
  UPDATE_STORY_ANALYSIS,
  UPDATE_STORY_ANALYSIS_SCORE,
  UPDATE_STORY_EXCERPT,
  UPDATE_STORY_UPDATED_AT,
  PROCESSING_STORY_PROPERTY,
  UPDATE_SELECTED_ANALYSIS_ASSESSMENT,
  UPDATE_STORY_FEATURED_IMAGE,
  UPDATE_STORY_FEATURED_IMAGE_ALT,
  PROCESSING_STORY_FEATURED_IMAGE,
  FETCH_STORY_PUBLISH_STATUS,
  UPDATE_STORY_PUBLISH_STATUS,
  UPDATE_STORY_SHARE_REPORT,
  GET_STORY,
  UPDATE_STORY_STATUS,
  SET_STORY_DUE_DATE,
  CLEAR_STORY_STATUS_PROPERTY,
  UPDATE_STORY_LOCK,
  ADD_STORY_BRIEF,
  UPDATE_STORY_BRIEF,
  DELETE_STORY_BRIEF,
  GET_STORY_REPORT,
  UPDATE_STORY_KEYWORD,
  ADD_STORY_TAG,
  ADD_STORY_CATEGORY,
  ADD_STORY_AUTHOR,
  SET_USED_TOPIC_SUGGESTIONS,
  UPDATE_STORY_APPROVAL_STATUS,
} from '../actions/types';
import { getISODate } from '@/date';

export default function (state = {}, action) {
  switch (action.type) {
    case UPDATE_STORY_KEYWORD: {
      return { ...state, keywords: action.payload };
    }
    case FETCH_STORY_PUBLISH_STATUS: {
      const destinations = state.destinations.map((destination) => {
        const destinationStatus = action.payload.find((status) => status.id === destination.id);
        if (destinationStatus) {
          return {
            ...destination,
            status: destinationStatus.status,
            message: destinationStatus.message,
            response: destinationStatus.response,
            help_url: destinationStatus.help_url,
            url: destinationStatus.url,
          };
        }
        return { ...destination, status: 'unpublished' };
      });
      return { ...state, destinations };
    }
    case GET_STORY:
      return { ...state, ...action.payload };
    case UPDATE_STORY_PUBLISH_STATUS:
      return { ...state, status_publish: action.payload };
    case UNPUBLISH_STORY:
      return { ...state, status_publish: 'unpublishing' };
    case UPDATE_STORY_EXCERPT:
      return { ...state, excerpt: action.payload, updated_at: getISODate() };
    case UPDATE_STORY_UPDATED_AT:
      return {
        ...state,
        updated_at: getISODate(),
      };
    case ADD_STORY_AUTHOR: {
      return { ...state, author: action.payload, updated_at: getISODate() };
    }
    case ADD_STORY_TAG: {
      return { ...state, tags: action.payload, updated_at: getISODate() };
    }
    case ADD_STORY_CATEGORY: {
      return { ...state, categories: action.payload, updated_at: getISODate() };
    }
    case PROCESSING_STORY_PROPERTY:
      return { ...state, status: 'processing', status_property: action.payload };
    case UPDATE_STORY_CONTENT:
      return {
        ...state,
        content: action.payload,
        updated_at: getISODate(),
      };
    case UPDATE_STORY_TITLE:
      return {
        ...state,
        title: action.payload,
        updated_at: getISODate(),
      };
    case UPDATE_STORY_SLUG:
      return {
        ...state,
        slug: action.payload,
        updated_at: getISODate(),
      };
    case UPDATE_STORY_HAS_UNCONFIRMED_CHANGES:
      return {
        ...state,
        has_unconfirmed_changes: action.payload,
      };
    case UPDATE_STORY_SEO_TITLE:
      return {
        ...state,
        seo_title: action.payload,
        updated_at: getISODate(),
      };
    case UPDATE_STORY_SEO_DESCRIPTION:
      return {
        ...state,
        seo_description: action.payload,
        updated_at: getISODate(),
      };
    case UPDATE_STORY_SEO_SLUG:
      return {
        ...state,
        seo_slug: action.payload,
        updated_at: getISODate(),
      };
    case SET_USED_TOPIC_SUGGESTIONS:
      return {
        ...state,
        usedTopicSuggestions: action.payload,
      };
    case UPDATE_STORY_ANALYSIS:
      return {
        ...state,
        analysis: action.payload,
      };
    case UPDATE_STORY_ANALYSIS_SCORE:
      return {
        ...state,
        analysis: {
          ...state.analysis,
          readability: { ...state.analysis.readability, score: action.payload.readability.score },
          seo: { ...state.analysis.seo, score: action.payload.seo.score },
        },
      };
    case UPDATE_STORY_LOCK:
      return {
        ...state,
        lock: action.payload,
      };
    case PROCESSING_STORY_FEATURED_IMAGE:
      return {
        ...state,
        featured_image: { ...state.featured_image, status: 'processing' },
      };
    case UPDATE_STORY_FEATURED_IMAGE:
      return {
        ...state,
        featured_image: { ...state.featured_image, src: action.payload, status: '' },
        updated_at: getISODate(),
      };
    case UPDATE_STORY_FEATURED_IMAGE_ALT:
      return {
        ...state,
        featured_image: { ...state.featured_image, alt: action.payload, status: '' },
        updated_at: getISODate(),
      };
    case UPDATE_SELECTED_ANALYSIS_ASSESSMENT: {
      return {
        ...state,
        selectedAnalysisAssessment: action.payload,
      };
    }
    case UPDATE_STORY_STATUS:
      return {
        ...state,
        ...action.payload,
      };
    case SET_STORY_DUE_DATE: {
      const { due_at } = action.payload;
      return { ...state, due_at };
    }
    case CLEAR_STORY_STATUS_PROPERTY: {
      if (state.status_property === action.payload) {
        return {
          ...state,
          status: '',
          status_property: '',
        };
      }
      return state;
    }
    case ADD_STORY_BRIEF: {
      const brief = action.payload;
      return { ...state, brief };
    }
    case UPDATE_STORY_BRIEF: {
      const brief = action.payload;
      return { ...state, brief };
    }
    case UPDATE_STORY_SHARE_REPORT: {
      const share_report = action.payload;
      return { ...state, share_report };
    }
    case DELETE_STORY_BRIEF: {
      return { ...state, brief: null };
    }
    case GET_STORY_REPORT: {
      return { ...state, report: action.payload };
    }
    case UPDATE_STORY_APPROVAL_STATUS: {
      return { ...state, approval_status: action.payload };
    }
    default:
      return state;
  }
}
