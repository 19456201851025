import { ADD_STORY_INTEGRATION, DELETE_STORY_INTEGRATION } from '../actions/types';

let storyIntegrations = null;
let index = null;
export default function (state = [], action) {
  switch (action.type) {
    case ADD_STORY_INTEGRATION: {
      storyIntegrations = state.story.integrations.slice();
      // upsert storyIntegration
      index = storyIntegrations.findIndex(
        (storyIntegration) => storyIntegration.id === action.payload.id,
      );
      if (index === -1) {
        // add storyIntegration
        storyIntegrations.push(action.payload);
      } else {
        // update storyIntegration
        storyIntegrations[index] = action.payload;
      }
      // update status integration
      const integrations = state.integrations.map((integration) => {
        if (integration.id === action.payload.destination_id) {
          return { ...integration, status: '' };
        }
        return integration;
      });
      return {
        ...state,
        story: { ...state.story, integrations: storyIntegrations },
        integrations,
      };
    }
    case DELETE_STORY_INTEGRATION: {
      storyIntegrations = state.story.integrations.slice();
      const storyIntegrationsFiltered = storyIntegrations.filter(
        (integration) => integration.id !== action.payload.id,
      );
      const integrations = state.integrations.map((integration) => {
        if (integration.id === action.payload.destination_id) {
          return { ...integration, status: '' };
        }
        return integration;
      });
      return {
        ...state,
        story: { ...state.story, integrations: storyIntegrationsFiltered },
        integrations,
      };
    }
    default:
      return state;
  }
}
