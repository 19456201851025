import reduceReducers from 'reduce-reducers';
import { combineReducers } from 'redux';
import storyReducer from './story';
import editorReducer from '@/editor/reducers/editor';
import destinationsReducer from '../../destinations/reducers/destinations';
import storyIntegrationsReducer from '../../storyIntegrations/reducers/storyIntegrations';
import storyDestinationsReducer from '../../storyDestinations/reducers/storyDestinations';
import storyRevisionsReducer from '../../storyRevisions/reducers';
import usersReducer from '../../account/reducers/users';
import tagsReducer from '../../account/reducers/tags';
import categoriesReducer from '../../account/reducers/categories';
import keywordsReducer from '../../account/reducers/keywords';
import exceptionReducer from '../../exceptions/reducers/exception';
import integrationsReducer from '../../integrations/reducers/integrations';
import uiReducer from '../../storychief/reducers/ui';
import presenceReducer from '../../presence/reducers/presence';
import lockReducer from '../../presence/reducers/lock';
import contactListsReducer from '../../contactLists/reducers/contactLists';
import storiesReducer from './stories';
import languagesReducer from '../../account/reducers/languages';
import customFieldsReducer from '../../account/reducers/custom-fields';
import referralOutreachesReducer from '@/stories/reducers/referralOutreaches';
import storyPreviewAuthorReducer from '@/stories/reducers/storyPreviewAuthorReducer';
import campaignsReducer from '@/campaigns/reducers/campaigns';
import approvalReviewsReducer from '@/reviews/reducers/approval-reviews';

const combinedReducer = combineReducers({
  contactLists: contactListsReducer,
  story: storyReducer,
  editor: editorReducer,
  destinations: destinationsReducer,
  integrations: integrationsReducer,
  users: usersReducer,
  tags: tagsReducer,
  categories: categoriesReducer,
  keywords: keywordsReducer,
  campaigns: campaignsReducer,
  exception: exceptionReducer,
  ui: uiReducer,
  presence: presenceReducer,
  lock: lockReducer,
  referralOutreaches: referralOutreachesReducer,
  previewAuthor: storyPreviewAuthorReducer,
});

export const storyReducers = reduceReducers(
  combinedReducer,
  storyDestinationsReducer,
  storyIntegrationsReducer,
  storyRevisionsReducer,
);

export const storiesReducers = combineReducers({
  stories: storiesReducer,
  destinations: destinationsReducer,
  ui: uiReducer,
  users: usersReducer,
  exception: exceptionReducer,
  tags: tagsReducer,
  keywords: keywordsReducer,
  categories: categoriesReducer,
  languages: languagesReducer,
  customFields: customFieldsReducer,
  campaigns: campaignsReducer,
  approvalReviews: approvalReviewsReducer,
});
