import {
  GET_STORY_DESTINATIONS_REPORTS,
  PROCESSING_STORY_DESTINATIONS_REPORTS,
} from '../actions/types';

let storyDestinations = null;
let referralOutreaches = null;

export default function (state = [], action) {
  switch (action.type) {
    case GET_STORY_DESTINATIONS_REPORTS: {
      storyDestinations = state.story.destinations.slice();
      referralOutreaches = state.story.referral_outreaches.slice();
      const reports = action.payload;
      storyDestinations = storyDestinations.map((destination) => {
        const report = reports.find((rep) => rep.story_destination_id === destination.id);
        if (report) {
          return {
            ...destination,
            report,
            status: '',
            status_property: '',
          };
        }
        return destination;
      });
      referralOutreaches = referralOutreaches.map((ro) => {
        const report = reports.find((rep) => rep.referral_outreach_id === ro.id);
        if (report) {
          return {
            ...ro,
            report,
            status: '',
            status_property: '',
          };
        }
        return ro;
      });

      return {
        ...state,
        story: {
          ...state.story,
          destinations: storyDestinations,
          referral_outreaches: referralOutreaches,
        },
      };
    }
    case PROCESSING_STORY_DESTINATIONS_REPORTS: {
      // update status destination
      storyDestinations = state.story.destinations.slice();
      storyDestinations = storyDestinations.map((destination) => ({
        ...destination,
        status: 'processing',
        status_property: 'report',
      }));
      return { ...state, story: { ...state.story, destinations: storyDestinations } };
    }
    default:
      return state;
  }
}
