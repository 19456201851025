import {
  GET_STORY_REVISIONS,
  GET_STORY_REVISION,
  PROCESSING_STORY_REVISION,
  PROCESSING_STORY_REVISIONS,
} from '../actions/types';

export default function (state = [], action) {
  switch (action.type) {
    case GET_STORY_REVISIONS: {
      return {
        ...state,
        story: { ...state.story, revisions: action.payload },
      };
    }
    case GET_STORY_REVISION: {
      const revisions = state.story.revisions.slice();
      let newRevisions = [];
      newRevisions = revisions.map((revision) => {
        if (revision.id === action.payload.id) {
          return {
            ...revision,
            status: '',
            ...action.payload,
          };
        }
        return revision;
      });
      return {
        ...state,
        story: { ...state.story, revisions: newRevisions },
      };
    }
    case PROCESSING_STORY_REVISION: {
      const revisions = state.story.revisions.slice();
      let newRevisions = [];
      newRevisions = revisions.map((revision) => {
        if (revision.id === action.payload) {
          return {
            ...revision,
            status: 'processing',
          };
        }
        return revision;
      });
      return {
        ...state,
        story: { ...state.story, revisions: newRevisions },
      };
    }
    case PROCESSING_STORY_REVISIONS: {
      return {
        ...state,
        story: { ...state.story, revisions: { status: 'processing' } },
      };
    }
    default:
      return state;
  }
}
