import { GET_CONTACT_LIST, PROCESSING_CONTACT_LIST } from '../actions/types';

export default function (state = [], action) {
  switch (action.type) {
    case GET_CONTACT_LIST: {
      const lists = state.slice();
      let newLists = [];
      if (lists.find((list) => list.id === action.payload.id)) {
        newLists = lists.map((list) => {
          if (list.id === action.payload.id) {
            return {
              ...action.payload,
            };
          }
          return list;
        });
      } else {
        lists.push({ ...action.payload });
        newLists = lists;
      }
      return newLists;
    }
    case PROCESSING_CONTACT_LIST: {
      const lists = state.slice();
      let newLists = [];
      if (lists.find((list) => list.id === action.payload)) {
        newLists = lists.map((list) => {
          if (list.id === action.payload) {
            return {
              ...list,
              status: 'processing',
            };
          }
          return list;
        });
      } else {
        lists.push({ id: action.payload, status: 'processing' });
        newLists = lists;
      }
      return newLists;
    }
    default:
      return state;
  }
}
